<template>
  <v-row justify="center" class="my-0 py-4">
    <Chat></Chat>

    <v-col cols="11" class="white rounded-xl">
      <v-row class="ma-1">
        <v-row class="ma-0 d-flex mx-auto">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
            <span class="wc-h6 text-primary-variant"
              >Crea publicaciones para tu audiencia activa</span
            >
          </v-col>
          <!--<v-col cols="1">
            <v-btn icon>
              <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
            </v-btn>-->

          <!-- <v-btn icon>
              <v-icon>mdi-tune</v-icon>
            </v-btn> 
          </v-col>-->
          <v-col cols="3" class="d-flex justify-center">
            <v-btn
              @click="createPost"
              color="accent"
              :absolute="!$vuetify.breakpoint.mdAndUp"
              bottom
              right
              :fixed="!$vuetify.breakpoint.mdAndUp"
              :style="$vuetify.breakpoint.mdAndUp || 'zIndex: 1; bottom: 20px;'"
              :rounded="$vuetify.breakpoint.mdAndUp"
              :fab="!$vuetify.breakpoint.mdAndUp"
              elevation="0"
            >
              <span
                v-if="$vuetify.breakpoint.mdAndUp"
                class="font-weight-bold black--text"
                >Nueva Publicación</span
              >
              <v-icon v-else size="30">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
      <div class="modal" transition="modal-slide-right">
        <!-- Modal content goes here -->
      </div>
      <v-divider></v-divider>
      <v-row>
        <v-col class="pa-0">
          <!--Component Tabs-->
          <v-tabs :color="color.primaryVariant" v-model="tab">
            <v-tab>
              <span class="wc-caption-1">Activas</span>
            </v-tab>
            <v-tab>
              <span class="wc-caption-1">Inactivas</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <post
                :status="'PUBLISHED'"
                :publications="fetchAllPublications"
                @refresh="getPublications"
              />
            </v-tab-item>
            <v-tab-item>
              <post
                :status="'DRAFT'"
                :publications="fetchAllPublications"
                @refresh="getPublications"
              />
            </v-tab-item>
          </v-tabs-items>
          <!--/Component Tabs-->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Chat from "@/components/shared/Chat.vue";

import { colors } from "@/constants/colors";
import Post from "../../components/dashboard/myCommunity/Posts.vue";
import { mapActions, mapMutations } from "vuex";
import { fetchPublications } from "../../store/publications/actions";

export default {
  components: {
    Post,
    Chat,
  },
  data() {
    return {
      color: colors,
      tab: null,
      publications: [],
      publication: {
        name: "",
        description: "",
        type: "BLOG",
        labels: [],
        images: [],
        status: "",
        value: "",
        status: "DRAFT",
      },
    };
  },
  computed: {
    tabContent() {
      if (this.selectedTab === "tab1") {
        // Logic for Tab 1 content
        return "Tab 1 Content";
      } else if (this.selectedTab === "tab2") {
        // Logic for Tab 2 content
        return "Tab 2 Content";
      }
    },

    fetchAllPublications() {
      return this.publications;
    },
  },
  methods: {
    ...mapActions("publications", ["fetchPublications", "createPublication"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("publications", ["setPublicationStatus"]),

    async getPublications() {
      let publications = (await fetchPublications()).data;
      console.log(publications);
      const reformatPublications = publications.map((publication) => {
        return {
          ...publication,
          hover: true,
          deleteDialog: false,
          formatedDate: this.formatDate(publication.createdAt),
        };
      });
      this.publications = reformatPublications;
    },

    async createPost() {
      let response = await this.createPublication(this.publication);
      this.setPublicationStatus({
        mode: "CREATE",
        id: response.data.data.publication._id,
      });
      this.$router.push({
        name: "newPublish",
      });
    },

    formatDate(currentDate) {
      const date = new Date(currentDate);
      const dateOptions = { day: "numeric", month: "short", year: "numeric" };
      const timeOptions = { hour: "numeric", minute: "numeric" };
      let fullDate = date
        .toLocaleDateString("es-MX", dateOptions)
        .replaceAll(" ", "-")
        .toUpperCase();
      let fullTime = date
        .toLocaleTimeString("en-MX", timeOptions)
        .toUpperCase();
      return {
        date: fullDate,
        time: fullTime,
      };
    },
  },

  async created() {
    try {
      this.loading();
      await this.getPublications();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<!--Styles-->
<style>
.rounded-progress {
  border-radius: 20px;
}
.analytic-card {
  height: 90px;
}
</style>
