<template>
  <v-container fluid class="mt-3">
    <v-row>
      <v-col
        cols="12"
        class="my-2"
        v-for="(item, i) in getPublicationsByStatus"
        :key="i"
      >
        <v-card
          elevation="3"
          @mouseover="item.hover = false"
          @mouseleave="item.hover = true"
        >
          <v-row class="px-2" :class="getBreakpoint ? 'py-6' : 'py-0'">
            <v-col
              :cols="getBreakpoint ? 7 : 12"
              class="d-flex align-center"
              :class="getBreakpoint ? 'pl-8' : 'pb-0'"
            >
              <v-avatar
                :size="getBreakpoint ? '100' : '40'"
                style="font-size: 28px; border-radius: 24px"
                :color="color.primaryVariantClear"
                class="mx-4 font-weight-bold rounded-lg"
              >
                <v-icon :size="getBreakpoint ? 50 : 25" color="#3aa4c6">
                  {{ getIcons(item.type) }}
                </v-icon>
              </v-avatar>
              <v-list-item three-line class="px-0">
                <v-list-item-content
                  class="pa-0 pointer"
                  @click="executeAction('EDIT', item)"
                >
                  <v-list-item-subtitle
                    class="text-uppercase"
                    :style="getBreakpoint || 'fontSize: 10px'"
                  >
                    {{ publicationStatus[item.status] }} el
                    {{ item.formatedDate.date }} - {{ item.formatedDate.time }}
                  </v-list-item-subtitle>
                  <span
                    :style="getBreakpoint || 'fontSize: 17px'"
                    style="
                      font-size: 20px;
                      font-weight: 800;
                      word-break: break-word;
                    "
                  >
                    {{ item.name }}
                  </span>
                  <v-list-item-subtitle
                    style="font-weight: 700; letter-spacing: 1.5px"
                  >
                    <!-- Autor: {{ getOwnerName(item.owner) }} -->
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="font-size: 13px; font-weight: 800"
                  >
                    <v-icon size="17">
                      <!-- mdi-account-multiple-outline  -->
                    </v-icon>
                    <!-- Miembros Nacionales -->
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div
                class="d-flex align-start"
                style="height: 100%"
                v-if="!getBreakpoint"
              >
              <v-menu >
                <template v-slot:activator="{on, attrs}">
                  <v-btn fab small text v-on="on" v-bind="attrs">
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                    <v-list>
                      <v-list-item v-for="(icon, i) in getActionsActive" :key="i">
                        <v-list-item-content @click="executeAction(icon.id, item)">
                          {{ icon.title }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
              </v-menu>
              </div>
            </v-col>
            <v-col
              v-if="getBreakpoint"
              :cols="getBreakpoint ? 5 : 12"
              class="d-flex align-center"
            >
              <v-row v-if="item.hover" :class="getBreakpoint ? 'mr-6' : 'pt-0'">
                <v-col
                  cols="12"
                  class="d-flex"
                  :class="getBreakpoint ? 'justify-end' : 'justify-start pt-0'"
                >
                  <v-chip outlined v-for="(label, i) in item.labels" :key="i">{{
                    label
                  }}</v-chip>
                </v-col>
              </v-row>
              <!-- <v-col cols="1" class="pa-0">
                  <v-divider vertical />
                </v-col> -->
              <!-- <v-col
                  cols="4"
                  class="d-flex align-center justify-center justify-space-around"
                >
                  <span class="coments-views"
                    ><v-icon color="#3aa4c6" class="mr-2">mdi-message-outline</v-icon
                    >0</span
                  >
                  <span class="coments-views"
                    ><v-icon color="#3aa4c6" class="mr-2">mdi-eye-outline</v-icon>0</span
                  >
                </v-col> -->

              <v-row v-else-if="getBreakpoint" class="mr-6">
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    v-for="(icon, i) in getActions"
                    :key="i"
                    class="mx-2"
                    small
                    :disabled="icon.disabled"
                    color="#3aa4c6"
                    :ripple="false"
                    fab
                    @click="executeAction(icon.id, item)"
                    text
                  >
                    <v-icon>
                      {{ icon.icon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <personalized-dialog
          hidden
          :dialog="item.deleteDialog"
          @save="deleteOrganizationPublication(item)"
          @discard="item.deleteDialog = false"
        >
          <template #alertName>
            <span>Eliminar Publicación</span>
          </template>
          <template #alertContent>
            <p class="title text-center">
              Estás seguro de que desea eliminar esta publicación?
            </p>
          </template>
        </personalized-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { colors } from "@/constants/colors.js";
import { mapActions, mapMutations } from "vuex";
import { types } from "@/constants/publicationTypes.js";
import PersonalizedDialog from "../../shared/PersonalizedDialog.vue";

export default {
  components: {
    PersonalizedDialog,
  },
  props: {
    status: {
      type: String,
      default: "",
    },
    publications: {
      type: Array,
      status: [],
    },
  },
  data() {
    return {
      color: colors,
      publicationType: types,
      publicationStatus: {
        PUBLISHED: "Publicado",
        DRAFT: "Archivado",
      },
      actions: [
        {
          icon: "mdi-forum-outline",
          id: "FORUM",
          disabled: true,
        },
        {
          icon: "mdi-archive-arrow-down-outline",
          id: "ARCHIVE",
          disabled: true,
        },
        {
          icon: "mdi-pencil-outline",
          id: "EDIT",
          title: "Editar"
        },
        {
          icon: "mdi-delete-outline",
          id: "DELETE",
          title: "Eliminar"
        },
      ],
    };
  },

  methods: {
    ...mapActions("publications", ["deletePublication"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show", "hide"]),
    ...mapMutations("publications", ["setPublicationStatus"]),

    getIcons(type) {
      let i = this.publicationType.findIndex((p) => p.value == type);
      return this.publicationType[i].icon;
    },
    getOwnerName(owner) {
      return `${owner.name || ""} ${owner.firstLastname || ""}`;
    },
    executeAction(flag, item) {
      console.log(flag);
      switch (flag) {
        case "EDIT":
          this.editUser(item);
          break;
        case "DELETE":
          this.handleDelete(item);
          break;
      }
    },

    editUser(item) {
      this.setPublicationStatus({ mode: "EDIT", id: item._id });
      this.$router.push({
        name: "newPublish",
      });
    },
    handleDelete(item) {
      console.log(item);
      item.deleteDialog = true;
    },
    async deleteOrganizationPublication(item) {
      try {
        this.loading();
        const response = await this.deletePublication(item);
        if (response.status) {
          this.show({
            text: "Se ha eliminado la publicación",
            color: "success",
            time: 3000,
          });
          item.deleteDialog = false;
          this.$emit("refresh");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
  },
  computed: {
    getActions() {
      return this.actions;
    },
    getActionsActive() {
      return this.actions.filter(action => { return action.disabled !== true });
    },
    getPublicationsByStatus() {
      return this.publications.filter((p) => p.status === this.status);
    },
    getBreakpoint() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  //methods:{
  //  ...mapActions("publication", ["updatePublication",
  //"fetchOrganizationPublicationById"])
  //},
  //async $_fetchOrganizartionPublications(){
  //  await this.fetchOrganizartionPublicationById(this.$route.params.currentPublication);
  //}
};
</script>
<style scoped>
.coments-views {
  color: #3aa4c6;
  font-weight: 500;
}
</style>

